<template>
  <div>
    <section class="q-info" v-if="quotationRequest && !userHasSubmittedQuotation && $store.user.isFootwearSupplier">
      <div class="q-info__section">
        <h2 class="page__heading">{{ quotationRequest.company.name }}</h2>
        <div class="page__content post-content">
          <p class="post-content__highlighted post-content__highlighted--small">
            {{ quotationRequest.company.description }}
          </p>
        </div>
        <quotation-info-brand :quotationRequest="quotationRequest"></quotation-info-brand>
      </div>

      <div class="q-info__section">
        <h2 class="page__heading">Quotation Information</h2>
        <div class="page__content post-content">
          <p class="post-content__highlighted post-content__highlighted--small">Information about potential order.</p>
        </div>
        <quotation-info :quotationRequest="quotationRequest"></quotation-info>
      </div>
      <div class="q-info__section">
        <h2 class="page__heading">Quotation Offer</h2>
        <div class="page__content post-content">
          <p class="post-content__highlighted post-content__highlighted--small">
            Please fulfill your quotation offer. This will be presented to the brand. Only complete information will be
            considered.
          </p>
        </div>
        <quotation-offer @update="getQuotationRequest" mode="add" />
      </div>
    </section>
    <section class="page" v-else-if="quotationRequest && userHasSubmittedQuotation && $store.user.isFootwearSupplier">
      <h2 class="page__heading">Thank you for submitting your offer</h2>
      <div class="page__content post-content">
        <p class="post-content__highlighted post-content__highlighted--small">
          You have successfully submitted your offer to FindSourcing. <br />We will go through your offer and contact
          you for next steps.
        </p>
        <p style="text-align: center">
          You can always see your submitted offers on the quotation overview page. <br />Click
          <router-link :to="{ name: 'quotationOverview' }">here</router-link>
          to access it or you'll be sent there automatically within {{ timer.seconds }} seconds.
        </p>
      </div>
    </section>
    <section class="page" v-else-if="quotationRequest && !$store.user.isFootwearSupplier">
      <h2 class="page__heading">Are you a footwear manufacturer?</h2>
      <div class="page__content post-content qi-error">
        <p class="post-content__highlighted post-content__highlighted--small">
          In order to view this page you need to be connected to a footwear supplier. <br />Press below button to
          proceed.
        </p>
        <!-- Style is there to prevent .post-content from parent to affect through .post-content a -->
        <!-- TODO: This should be fixed (including removing the style) -->
        <fs-button-main :long="true" size="large" :to="{ name: 'supplierAdd' }" style="color: #fff"
          >Register as a footwear supplier</fs-button-main
        >
      </div>
    </section>
    <div class="loader is-visible" v-if="!quotationRequest"></div>
  </div>
</template>

<script>
import countDown from './../lib/timers';

import FsButtonMain from '@components/buttons/fs-button-main.vue';
import QuotationInfoBrand from './quotation-info-brand-intro.vue';
import QuotationInfo from './quotation-info-quotation.vue';
import QuotationOffer from './quotation-offer.vue';

export default {
  load({ store, route }) {
    store.head.title = `FindSourcing – Source Suppliers`;

    return store.currency.loadMainCurrencies();
  },
  components: { FsButtonMain, QuotationInfoBrand, QuotationOffer, QuotationInfo },
  data() {
    return {
      quotationRequest: null,
      siema1isReady: false,
      siema2isReady: false,
      siema1curSlide: 0,
      siema2curSlide: 0,
      userHasSubmittedQuotation: null,
      timer: {},
    };
  },
  methods: {
    async getQuotationRequest() {
      const { request } = await this.$store.quotations.getRequest(this.$route.params.id);

      this.quotationRequest = request;

      this.userHasSubmittedQuotation =
        this.$store.user.isFootwearSupplier &&
        this.quotationRequest.quotations.some((q) => q.supplier.id == this.$store.user.company.id);

      if (this.userHasSubmittedQuotation) {
        this.timer = countDown(this, 5, () => {
          this.$router.push({ name: 'quotationOverview' });
        });
      }
    },
  },
  watch: {},
  async mounted() {
    await this.getQuotationRequest();
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.qi-error {
  text-align: center;
  a.qi-error-button {
    display: inline-block;
    color: $c-light;
    margin-left: 0;
    &:hover {
      background: rgba($c-blue, 0.7);
    }
  }
}
</style>
