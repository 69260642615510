var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.quotationRequest &&
    !_vm.userHasSubmittedQuotation &&
    _vm.$store.user.isFootwearSupplier
      ? _c("section", { staticClass: "q-info" }, [
          _c(
            "div",
            { staticClass: "q-info__section" },
            [
              _c("h2", { staticClass: "page__heading" }, [
                _vm._v(_vm._s(_vm.quotationRequest.company.name)),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "page__content post-content" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "post-content__highlighted post-content__highlighted--small",
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.quotationRequest.company.description) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _c("quotation-info-brand", {
                attrs: { quotationRequest: _vm.quotationRequest },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "q-info__section" },
            [
              _c("h2", { staticClass: "page__heading" }, [
                _vm._v("Quotation Information"),
              ]),
              _vm._v(" "),
              _vm._m(0),
              _vm._v(" "),
              _c("quotation-info", {
                attrs: { quotationRequest: _vm.quotationRequest },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "q-info__section" },
            [
              _c("h2", { staticClass: "page__heading" }, [
                _vm._v("Quotation Offer"),
              ]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("quotation-offer", {
                attrs: { mode: "add" },
                on: { update: _vm.getQuotationRequest },
              }),
            ],
            1
          ),
        ])
      : _vm.quotationRequest &&
        _vm.userHasSubmittedQuotation &&
        _vm.$store.user.isFootwearSupplier
      ? _c("section", { staticClass: "page" }, [
          _c("h2", { staticClass: "page__heading" }, [
            _vm._v("Thank you for submitting your offer"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "page__content post-content" }, [
            _vm._m(2),
            _vm._v(" "),
            _c(
              "p",
              { staticStyle: { "text-align": "center" } },
              [
                _vm._v(
                  "\n        You can always see your submitted offers on the quotation overview page. "
                ),
                _c("br"),
                _vm._v("Click\n        "),
                _c(
                  "router-link",
                  { attrs: { to: { name: "quotationOverview" } } },
                  [_vm._v("here")]
                ),
                _vm._v(
                  "\n        to access it or you'll be sent there automatically within " +
                    _vm._s(_vm.timer.seconds) +
                    " seconds.\n      "
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm.quotationRequest && !_vm.$store.user.isFootwearSupplier
      ? _c("section", { staticClass: "page" }, [
          _c("h2", { staticClass: "page__heading" }, [
            _vm._v("Are you a footwear manufacturer?"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "page__content post-content qi-error" },
            [
              _vm._m(3),
              _vm._v(" "),
              _c(
                "fs-button-main",
                {
                  staticStyle: { color: "#fff" },
                  attrs: {
                    long: true,
                    size: "large",
                    to: { name: "supplierAdd" },
                  },
                },
                [_vm._v("Register as a footwear supplier")]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    !_vm.quotationRequest
      ? _c("div", { staticClass: "loader is-visible" })
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content post-content" }, [
      _c(
        "p",
        {
          staticClass:
            "post-content__highlighted post-content__highlighted--small",
        },
        [_vm._v("Information about potential order.")]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page__content post-content" }, [
      _c(
        "p",
        {
          staticClass:
            "post-content__highlighted post-content__highlighted--small",
        },
        [
          _vm._v(
            "\n          Please fulfill your quotation offer. This will be presented to the brand. Only complete information will be\n          considered.\n        "
          ),
        ]
      ),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass:
          "post-content__highlighted post-content__highlighted--small",
      },
      [
        _vm._v(
          "\n        You have successfully submitted your offer to FindSourcing. "
        ),
        _c("br"),
        _vm._v(
          "We will go through your offer and contact\n        you for next steps.\n      "
        ),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "p",
      {
        staticClass:
          "post-content__highlighted post-content__highlighted--small",
      },
      [
        _vm._v(
          "\n        In order to view this page you need to be connected to a footwear supplier. "
        ),
        _c("br"),
        _vm._v("Press below button to\n        proceed.\n      "),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }